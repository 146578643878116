.header {
    display: flex;
    flex-direction: row;
}

.header__title {
    font-family: 'Viga';
    font-size: 4em;
    color: var(--limed);
    flex: 1;
    line-height: 112px;
}

.header__navigation {
    background-color: var(--limed);
    height: 72px;
    border-radius: 34px;
    margin-top: 24px;

    -webkit-box-shadow: 0px 0px 16px 4px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 16px 4px rgba(0,0,0,0.1);
}

.header__navigation-list {
    display: flex;
    flex-direction: row;
    padding: 8px;
    padding-right: 0;
}

.header__navigation-item {
    flex: 1;
    line-height: 56px;
    border-radius: 28px;
    padding: 0 16px;
    font-size: 1.2em;
    color: var(--white);
    font-family: 'OpenSans';
    margin-right: 8px;
}

.header__navigation-item:hover {
    cursor: pointer;
    background-color: var(--larkspur);
}

.header__navigation-item--active {
    background-color: var(--larkspur);
}
