.active-yield {
    flex: 1;
    display: flex;
}

.active-yield__container {
    flex: 1;
}

.active-yield__value {
    text-align: center;
    font-family: 'Viga';
    font-size: 3em;
    color: var(--limed);
    padding-bottom: 8px;
}

.active-yield__value-modifier {
    font-size: 1.5em;
    color: var(--larkspur);
}

.active-yield__description {
    text-align: center;
    font-family: 'Viga';
    font-size: 2em;
    color: var(--limed);
}

.active-yield__solar-panel {
  width: calc(100% - 20px);
  padding-bottom: 16px;
  margin-top: -16px;
}
