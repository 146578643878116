main {
  height: 100%;
}

.container {
  width: 1550px;
  margin: 0 auto;
  height: 100%;
}

.divider {
  margin: 0 -16px;
  display: flex;
  flex-direction: row;
  height: calc(100% - 192px);
}

.divider__two-thirds {
  flex: 3;
  display: flex;
  flex-direction: column;
  margin: 0 16px;
}

.divider__one-thirds {
  flex: 2;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
}


@media only screen and (min-width: 1620px) {
  .container {
    width: 1550px;
  }
}

@media only screen and (min-width: 1720px) {
  .container {
    width: 1650px;
  }
}

@media only screen and (min-width: 1920px) {
  .container {
    width: 1850px;
  }
}

@media only screen and (min-width: 2100px) {
  .container {
    width: 2050px;
  }
}

@media only screen and (min-width: 2250px) {
  .container {
    width: 2200px;
  }
}

@media only screen and (min-width: 2560px) {
  .container {
    width: 2500px;
  }
}

@media only screen and (min-width: 3840px) {
  .container {
    width: 3780px;
  }
}
