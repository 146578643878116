.graph {
    margin: 32px 0;
    flex: 1;
    background-color: var(--milky-way);
    border-radius: 35px;
    overflow: hidden;

    -webkit-box-shadow: 0px 0px 16px 4px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 16px 4px rgba(0,0,0,0.1);
}

.graph__header {
    background-color: var(--limed);
}

.graph__title {
    line-height: 56px;
    text-align: center;
    color: var(--white);
    font-size: 2em;
    font-family: 'OpenSans Bold';
}

.graph__content {
    height: calc(100% - 88px);
    margin-left: 42px;
    margin-top: 48px;
    position: relative;
}

.graph__content svg {
  overflow: visible;
}

.graph-active-yield {
  position: absolute;
  top: -40px;
  right: 8px;
  padding: 8px 32px;
  background-color: var(--limed);
  color: var(--white);
  text-align: center;
  border-radius: 12px;
}

.graph-active-yield__title {
  font-family: 'Viga';
  font-size: 1.3em;
  line-height: 26px;
}

.graph-active-yield__sub-title {
  font-family: 'Viga';
  font-size: 1em;
}

.graph-active-yield__yield {
  font-family: 'Viga';
  font-size: 2.5em;
  line-height: 48px;
  color: var(--larkspur);
  text-align: right;
}
