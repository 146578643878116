.highlights-item {
    flex: 1;
    background-color: var(--milky-way);
    min-height: 260px;
    border-radius: 35px;
    margin: 0 16px;
    text-align: center;
    display: flex;
    justify-content: center;

    -webkit-box-shadow: 0px 0px 16px 4px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 16px 4px rgba(0,0,0,0.1);

    transition: opacity 2s;
    transition-timing-function: ease-out;
}

.highlights-item__container {
    align-self: center;
}

.highlights-item__image {
    margin-bottom: 8px;
    height: 60px;
}

.highlights-item__count {
    font-size: 4em;
    font-family: 'Viga';
    color: var(--limed);
    padding-bottom: 12px;
}

.highlights-item__description {
    font-family: 'Viga';
    color: var(--limed);
    font-size: 1.3em;
}

.highlights-item__animated {
  display: none;
  opacity: 0;
}

.highlights-item__visible {
  display: flex;
}

.highlights-item__animation-active {
  opacity: 1;
}

.highlights-item__closing {
  transition: opacity 0.3s !important;
  opacity: 0;
}
