.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 80px;
    width: 100%;
    background-color: var(--larkspur);
    text-align: center;
}

.footer__logo {
    height: 32px;
    margin-top: 24px;
}
