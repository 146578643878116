@font-face {
  font-family: "Viga";
  src: url('assets//fonts/viga/viga-regular.ttf');
}

@font-face {
  font-family: "OpenSans";
  src: url('assets/fonts/open-sans/open-sans-semi-bold.ttf');
}

@font-face {
  font-family: "OpenSans bold";
  src: url('assets/fonts/open-sans/open-sans-bold.ttf');
}

:root {
  --white: #fff;
  --larkspur: #18adaf;
  --limed: #394a51;
  --milky-way: #e7f7f7;
  --polar: #d0dddd;
}

html, body, #root, main {
  height: 100%;
}
